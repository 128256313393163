
const appendHeading = ( elm ) => {
  const accordionItems = elm?.querySelectorAll( '.e-acc__itm-wrpr' );
  accordionItems.forEach( function ( accordionItem ) {
    const accordionHeader = accordionItem.querySelector( '.e-acc__itm-hdr-wrpr' );
    const accordionBody = accordionItem.querySelector( '.e-acc__itm-bdy' );

    if ( accordionHeader && accordionBody ) {
      const headerHTML = accordionHeader.innerHTML;
      accordionBody.innerHTML += headerHTML;
    }
  } );
};

document.addEventListener( 'DOMContentLoaded', () => {
  const valueCardsSections = document.querySelectorAll( '.e-stn--platform-overview-value-cards' );
  if ( valueCardsSections.length === 0 ) return;
  valueCardsSections.forEach( function ( valueCardsSection ) {
    appendHeading( valueCardsSection );
  } );
} );