document.addEventListener( 'DOMContentLoaded', function () {
  const tabsSection = document.querySelector( '.e-stn--tabs-section' );
  if ( !tabsSection ) return;

  // Function to check for image in the active floating panel
  const checkActivePanelForImage = () => {
    const currentActivePanel = tabsSection.querySelector( '.e-acc__flt-itm-wrpr.active' );
    if ( currentActivePanel ) {
      const img = currentActivePanel.querySelector( 'img' );
      if ( img ) {
        tabsSection.classList.remove( 'bg-animation-hidden' );
      } else {
        tabsSection.classList.add( 'bg-animation-hidden' );
      }
    } else {
      tabsSection.classList.add( 'bg-animation-hidden' );
    }
  };

  checkActivePanelForImage();

  const tabs = tabsSection.querySelectorAll( '.e-acc__itm-wrpr' );

  tabs.forEach( ( tab ) => {
    tab.addEventListener( 'click', function () {
      checkActivePanelForImage();
    } );
  } );
} );
