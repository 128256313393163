document.addEventListener( 'DOMContentLoaded', function() {
  function setMediaElementAttributes( mediaElements ) {
    mediaElements.forEach( ( mediaElement, index ) => {
      mediaElement.id = `embed-${index}`;
      mediaElement.setAttribute(
        'allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture' );
    } );
  }

  function postMessageToIframe( e, message ) {
    e.contentWindow.postMessage( JSON.stringify( message ), '*' ); // NOSONAR
  }

  function handleIframeStatus( e, action ) {
    const src = e.getAttribute( 'src' );
    const isVimeo = src.includes( 'vimeo' );
    const isYoutube = src.includes( 'youtube' );
    const isWistia = src.includes( 'wistia' );

    if ( isVimeo || isWistia ) {
      if ( action === 'play' ) {
        postMessageToIframe( e, { method: 'seekTo', value: 0 } );
      }
      postMessageToIframe( e, { method: action, value: true } );
    } else if ( isYoutube ) {
      postMessageToIframe( e, { event: 'command', func: `${action}Video` } );
    }
  }

  function handleVideoStatus( e, action ) {
    if ( action === 'pause' ) {
      e.pause();
    } else if ( action === 'play' ) {
      e.currentTime = 0;
      e.play();
    }
  }

  function videoStatus( e, action ) {
    if ( e.tagName.toLowerCase() === 'iframe' ) {
      handleIframeStatus( e, action );
    } else if ( e.tagName.toLowerCase() === 'video' ) {
      handleVideoStatus( e, action );
    }
  }

  function handleIntersection( entries ) {
    entries.forEach( entry => {
      const target = entry.target;
      videoStatus( target, entry.isIntersecting ? 'play' : 'pause' );
    } );
  }

  function observeVideos( videos ) {
    const observer = new IntersectionObserver( handleIntersection );
    videos.forEach( video => observer.observe( video ) );
  }

  function handleClickOnPlaceholder( mediaElements, e ) {
    const parentItem = e.target.closest( 'div' );
    const figureElement = e.target.closest( 'figure' );

    resetMediaElements( mediaElements );
    playClickedVideo( parentItem, figureElement );
  }

  function resetMediaElements( mediaElements ) {
    mediaElements.forEach( mediaElement => {
      videoStatus( mediaElement, 'pause' );
      const mediaParent = mediaElement.closest( 'div' );
      if ( mediaParent ) {
        mediaParent.classList.remove( 'play-video' );
        const figure = mediaParent.querySelector( 'figure' );
        if ( figure ) figure.style.display = 'block';
      }
    } );
  }

  function playClickedVideo( parentItem, figureElement ) {
    parentItem.classList.add( 'play-video' );
    if ( figureElement ) figureElement.style.display = 'none';

    const mediaElementsInParent = parentItem.querySelectorAll( 'iframe, video' );
    mediaElementsInParent.forEach( mediaElement => {
      if ( mediaElement.tagName.toLowerCase() === 'iframe' ) {
        handleIframeStatus( mediaElement, 'play' );
      } else if ( mediaElement.tagName.toLowerCase() === 'video' ) {
        mediaElement.currentTime = 0;
        mediaElement.play();
      }
    } );
  }

  function mediaVideo() {
    const elements = document.querySelectorAll( '.e-div--js-video-replay' );
    elements.forEach( element => {
      const mediaElements = element.querySelectorAll( 'iframe, video' );
      setMediaElementAttributes( mediaElements );
      const videos = element.querySelectorAll( '[id^=embed-]' );
      observeVideos( videos );

      element.addEventListener( 'click', function( e ) {
        if ( e.target.closest( 'figure' ) ) {
          handleClickOnPlaceholder( mediaElements, e );
        }
      } );
    } );
  }

  mediaVideo();
} );