document.addEventListener( 'DOMContentLoaded', () => {
  const partnerInnerNavs = document.querySelectorAll( '.e-stn--partner-tabs nav' );
  if ( partnerInnerNavs.length === 0 ) return;

  partnerInnerNavs.forEach( partnerInnerNav => {
    const currentItem = partnerInnerNav.querySelector( '.current-menu-item' );
    const span = document.createElement( 'span' );
    partnerInnerNav.appendChild( span );
    span.textContent = currentItem ? currentItem.textContent : 'OTHER LINKS';
    
    span.addEventListener( 'click', () => {
      partnerInnerNav.classList.toggle( 'active' );
    } );
  } );
} );
