function handleMouseEnter( partnerPost, index ) {
  partnerPost.addEventListener( 'mouseenter', ( e ) => {
    const $this = e.target;
    $this.classList.add( 'pst-hvd' );
    applyClassBasedOnIndex( $this, index, 'add' );
  } );
}

function handleMouseLeave( partnerPost, index ) {
  partnerPost.addEventListener( 'mouseleave', ( e ) => {
    const $this = e.target;
    $this.classList.remove( 'pst-hvd' );
    applyClassBasedOnIndex( $this, index, 'remove' );
  } );
}

function applyClassBasedOnIndex( element, index, action ) {
  if ( [0, 3, 6].includes( index ) ) {
    const next = element.nextElementSibling;
    const after = next?.nextElementSibling;
    [element, next, after].filter( el => el ).forEach( el => {
      if ( action === 'add' ) {
        el.classList.add( 'crd-mve-rgt' );
      } else {
        el.classList.remove( 'crd-mve-rgt' );
      }
    } );
  } else if ( [1, 4, 7].includes( index ) ) {
    const prev = element.previousElementSibling;
    const next = element.nextElementSibling;
    [element, prev, next].filter( el => el ).forEach( el => {
      if ( action === 'add' ) {
        el.classList.add( 'crd-mve-cntr' );
      } else {
        el.classList.remove( 'crd-mve-cntr' );
      }
    } );
  } else if ( [2, 5, 8].includes( index ) ) {
    const prev = element.previousElementSibling;
    const before = prev?.previousElementSibling;
    [element, prev, before].filter( el => el ).forEach( el => {
      if ( action === 'add' ) {
        el.classList.add( 'crd-mve-lft' );
      } else {
        el.classList.remove( 'crd-mve-lft' );
      }
    } );
  }
}

function initializeStrategicCards() {
  const partnerSection = document.querySelector( '.e-stn--partners-strategic .b3rg-posts' );
  if ( !partnerSection ) return;

  const partnerPosts = partnerSection.querySelectorAll( '.e-col' );
  partnerPosts.forEach( ( partnerPost, index ) => {
    partnerPost.setAttribute( 'data-index', index );
    handleMouseEnter( partnerPost, index );
    handleMouseLeave( partnerPost, index );
  } );
}

document.addEventListener( 'DOMContentLoaded', initializeStrategicCards );
document.addEventListener( 'facetwp-refresh', function() {
  FWP.hooks.addAction( 'facetwp/loaded', initializeStrategicCards, 10 ); // eslint-disable-line no-undef
} );
