document.addEventListener( 'DOMContentLoaded', function() {
  let targetElement = document.querySelector( '.e-col--research-hub-out-now-popup > .e-div' );
  if ( targetElement ) {
    targetElement.classList.add( 'active' );
    const trigger = targetElement.querySelector( 'p' );
        
    trigger.addEventListener( 'click', function() {
      targetElement.classList.toggle( 'active' );
    } );
  }
} );