document.addEventListener( 'DOMContentLoaded', () => {
  let stickyWrapper = document.querySelector( '.e-div--js-bi-sticky-left' );
  if ( !stickyWrapper ) {
    return;
  }
  const checkViewportHeight = () => {
    const stickyWrapperHeight = stickyWrapper.getBoundingClientRect().height;
    const viewportHeight = window.innerHeight;
    const stickyTop = document.querySelector( 'header' ).offsetHeight;
    if ( ( viewportHeight - stickyTop ) > stickyWrapperHeight ) {
      stickyWrapper.classList.add( 'sticky-bi-div' );
    } else {
      stickyWrapper.classList.remove( 'sticky-bi-div' );
    }
  };

  checkViewportHeight();
  window.addEventListener( 'resize', checkViewportHeight );
} );
