const scrollAccordion = {
  DOM: document.querySelectorAll( '.e-col--scroll-accordion' ),
  observerActive: true,

  /**
   * Function to determine the appropriate rootMargin based on window width
   */
  determineRootMargin: () => {
    const width = window.innerWidth;
    if ( width > 4500 ) {
      return '-15% 0px -83% 0px';
    } else if ( width > 2500 ) {
      return '-25% 0px -72% 0px';
    } else if ( width > 991 ) {
      return '-25% 0px -55% 0px';
    } else {
      return '-30% 0px -50% 0px';
    }
  },

  /**
   * Function to get dynamic observer options
   */
  getObserverOptions: () => {
    const rootMargin = scrollAccordion.determineRootMargin();
    const threshold = window.innerWidth > 991 ? 0.1 : 0.2;
    return { rootMargin, threshold };
  },

  /**
   * Setup left side menu
   * @param {Element} acc 
   */
  setupMenu: ( acc ) => {
    const menuItems = acc.querySelectorAll( '.e-acc__itm-hdr' );
    const menuWrapper = acc.querySelector( '.e-div--scroll-accordion-menu' );
    if ( !menuWrapper || menuItems.length === 0 ) return;

    menuWrapper.innerHTML = `
      <ul>${Array.from( menuItems ).map( ( item, i ) => `
        <li data-index="${i}" class="acc-menu-item ${i === 0 ? 'active' : ''}">${item.innerHTML}</li>
      ` ).join( '' )}</ul>
    `;
    scrollAccordion.bindEvents( acc );
  },

  /**
   * Bind custom events to the accordion
   * @param {Element} acc 
   */
  bindEvents: ( acc ) => {
    acc.querySelectorAll( '.acc-menu-item' ).forEach( trigger => {
      trigger.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        scrollAccordion.observerActive = false;
        const itemIndex = trigger.getAttribute( 'data-index' );
        scrollAccordion.activateItem( acc, itemIndex );
      } );
    } );
  },

  /**
   * Activate observed / clicked item
   * @param {Element} acc Accordion element
   * @param {Integer} i item index
   * @param {Boolean} scroll Scroll to section if true
   */
  activateItem: ( acc, i, scroll = true ) => {
    const contentItems = acc.querySelectorAll( '.e-acc__itm-wrpr' );
    const menuTriggers = acc.querySelectorAll( '.acc-menu-item' );
    scrollAccordion.clearActiveItems( contentItems, menuTriggers );

    if ( scroll ) {
      const targetPosition = contentItems[i].getBoundingClientRect().top + window.scrollY - 130;
      window.scrollTo( { top: targetPosition, behavior: 'smooth' } );

      window.setTimeout( () => {
        scrollAccordion.observerActive = true;
      }, 500 );
    }

    contentItems[i].classList.add( 'active' );
    menuTriggers[i].classList.add( 'active' );

    const video = contentItems[i].querySelector( '.e-vid video' );
    if ( video ) {
      video.currentTime = 0;
      video.loop = true;
      video.play();
    }
  },

  /**
   * Clear active items
   * @param {NodeList} contentItems Contents
   * @param {NodeList} menuTriggers Menu items
   */
  clearActiveItems: ( contentItems, menuTriggers ) => {
    contentItems.forEach( content => {
      content.classList.remove( 'active' );
      const video = content.querySelector( '.e-vid video' );
      if ( video ) {
        video.pause();
      }
    } );
    menuTriggers.forEach( menuItem => menuItem.classList.remove( 'active' ) );
  },

  /**
   * Set up intersection observer
   * @param {Element} acc 
   */
  setObserver: ( acc ) => {
    const contentItems = acc.querySelectorAll( '.e-acc__itm-wrpr' );
    const observer = new IntersectionObserver( ( entries ) => {
      if ( !scrollAccordion.observerActive ) return;

      entries.forEach( entry => {
        if ( entry.isIntersecting ) {
          const index = Array.from( contentItems ).indexOf( entry.target );
          scrollAccordion.activateItem( acc, index, false );
        }
      } );
    }, scrollAccordion.getObserverOptions() );

    contentItems.forEach( item => observer.observe( item ) );
  },

  /**
   * Initialize
   */
  init: () => {
    if ( scrollAccordion.DOM.length === 0 ) return;
    scrollAccordion.DOM.forEach( elm => {
      scrollAccordion.setupMenu( elm );
      scrollAccordion.setObserver( elm );
    } );
  }
};

document.addEventListener( 'DOMContentLoaded', scrollAccordion.init );
