/* eslint-disable max-len*/
const { feWp } = window;
const { addAction } = feWp.hooks;

let loops = [];

function playSVGAnimation( player, card = false ) {
  if ( typeof player.play === 'function' && ( !card || !card.hasAttribute( 'data-disabled' ) ) ) {
    player.play();
  }
}

function setupAnimationLoop( player, card, interval = 2500 ) {
  return setInterval( () => playSVGAnimation( player, card ), interval );
}

function playAnimationWithDelay( player, delay, card ) {
  loops.push( setTimeout( () => playSVGAnimation( player, card ), delay ) );
}

function playAnimationOnHover( player, card, selector ) {
  const cards = document.querySelectorAll( selector );
  card.addEventListener( 'mouseenter', () => {
    clearLoops();
    disableCards( cards, card );
    const hoveredLoop = setupAnimationLoop( player, card );
    card.addEventListener( 'mouseleave', () => {
      clearInterval( hoveredLoop );
      enableCards( cards );
      if ( !loops.length ) customAnimateSVGatorSection( selector );
    } );
  } );
}

function clearLoops() {
  loops.forEach( loop => clearInterval( loop ) );
  loops.length = 0;
}

function disableCards( cards, exceptionCard ) {
  cards.forEach( card => {
    if ( card !== exceptionCard ) card.setAttribute( 'data-disabled', true );
  } );
}

function enableCards( cards ) {
  cards.forEach( card => card.removeAttribute( 'data-disabled' ) );
}

function animateSVGsOnSection( SVGDocument, card, i, sectionsLength, selector, hoverEffect ) {
  const SVGAnim = SVGDocument.querySelector( 'svg' );
  const player = SVGAnim?.svgatorPlayer;
  if ( player ) {
    player.set( 'iterations', 1 );
    hoverEffect && playAnimationOnHover( player, card, selector );
    playAnimationWithDelay( player, i * 2000, card );
    loops.push( setTimeout( () => animateSVGsOnSection( SVGDocument, card, i, sectionsLength, selector ), sectionsLength * 2500 ) );
  }
}

function customAnimateSVGatorSection( selector, hoverEffect = true ) {
  const sections = document.querySelectorAll( selector );
  sections.forEach( ( card, i ) => {
    setupIntersectionObserver( card, i, sections.length, selector, hoverEffect );
  } );
}

function setupIntersectionObserver( card, i, sectionsLength, selector, hoverEffect ) {
  const observer = new IntersectionObserver( entries => {
    handleObserverEntries( entries, card, i, sectionsLength, selector, hoverEffect );
  }, { threshold: 0.5 } );
  observer.observe( card );
}

function handleObserverEntries( entries, card, i, sectionsLength, selector, hoverEffect ) {
  entries.forEach( entry => {
    if ( entry.isIntersecting ) {
      processIntersection( card, i, sectionsLength, selector, hoverEffect );
    } else {
      clearLoops();
    }
  } );
}

function processIntersection( card, i, sectionsLength, selector, hoverEffect ) {
  const objectTag = card.querySelector( '.e-svg object' );
  const swiperContainer = card.closest( '.swiper-container' );
  const swiper = swiperContainer?.swiper;
  if ( swiper ) {
    handleSwiperIntersection( swiper, objectTag );
  } else {
    handleObjectTagLoad( objectTag, card, i, sectionsLength, selector, hoverEffect );
  }
}

function handleSwiperIntersection( swiper, objectTag ) {
  const activeIndex = swiper.activeIndex;
  const activeSlide = swiper.slides[activeIndex];
  const activeObjectTag = activeSlide.querySelector( '.e-svg object' );
  if ( activeObjectTag === objectTag ) {
    loadAndAnimateSVG( objectTag );
  }
}

function handleObjectTagLoad( objectTag, card, i, sectionsLength, selector, hoverEffect ) {
  if ( objectTag?.contentDocument ) {
    animateSVGsOnSection( objectTag.contentDocument, card, i, sectionsLength, selector, hoverEffect );
  } else {
    objectTag?.addEventListener( 'load', () => {
      if ( objectTag?.contentDocument ) {
        animateSVGsOnSection( objectTag.contentDocument, card, i, sectionsLength, selector, hoverEffect );
      }
    }, { once: true } );
  }
}

function loadAndAnimateSVG( objectTag ) {
  if ( objectTag?.contentDocument ) {
    animateSVG( objectTag.contentDocument );
  } else if ( objectTag ) {
    objectTag.addEventListener( 'load', () => {
      if ( objectTag?.contentDocument ) {
        animateSVG( objectTag.contentDocument );
      }
    }, { once: true } );
  }
}

function animateSVG( SVGDocument ) {
  const svgElement = SVGDocument.querySelector( 'svg' );
  const player = svgElement?.svgatorPlayer;
  if ( player ) {
    player.set( 'iterations', 1 );
    player.play();
  }
}

setTimeout( () => {
  customAnimateSVGatorSection( '.e-stn--platform-features-cards .swiper-slide > .e-lnk--platform-features-card' );
}, 1000 );

addAction(
  'b3rg_slider_init',
  'b3rg/slider',
  ( settings, el, swiper ) => {
    swiper.on( 'slideChange', () => {
      const activeSlide = swiper.slides[swiper.activeIndex];
      const objectTag = activeSlide.querySelector( '.e-svg object' );
      loadAndAnimateSVG( objectTag );
    } );
  },
  10,
  1
);
