document.addEventListener( 'DOMContentLoaded', () => {
  const parentElements = document.querySelectorAll( '.heateor_sss_sharing_ul' );
  const socialShareTooltips = document.querySelectorAll( '.e-div--social-share-tooltip' );

  if ( parentElements.length > 0 && socialShareTooltips.length > 0 ) {
    parentElements.forEach( parentElement => {
      const commonTooltipWrapper = document.createElement( 'span' );
      commonTooltipWrapper.className = 'ss-common-tooltip';
      parentElement.insertBefore( commonTooltipWrapper, parentElement.firstChild );

      commonTooltipWrapper.addEventListener( 'click', ( event ) => {
        event.stopPropagation(); // Prevent event from bubbling up to the document
        socialShareTooltips.forEach( tooltip => {
          tooltip.classList.toggle( 'active' );
        } );
      } );
    } );

    document.addEventListener( 'click', ( event ) => {
      if ( !event.target.closest( '.e-div--social-share-tooltip' ) ) {
        socialShareTooltips.forEach( tooltip => {
          tooltip.classList.remove( 'active' );
        } );
      }
    } );

    const copyLinkButtons = document.querySelectorAll( '.heateor_sss_button_copy_link' );
    copyLinkButtons.forEach( button => {
      button.addEventListener( 'click', function( event ) {
        event.preventDefault();
        const linkToCopy = this.getAttribute( 'href' );
        if ( window.isSecureContext && navigator.clipboard ) {
          navigator.clipboard.writeText( linkToCopy );
        }
      } );
    } );
  }
} );
