function initAnimationForSection( className ) {
  document.addEventListener( 'DOMContentLoaded', function () {
    const section = document.querySelector( `.${className}` );
    if ( !section ) return;
  
    const observerOptions = {
      root: null, // Use the viewport as the container
      rootMargin: '0px',
      threshold: 0.75, // Trigger when 75% of the section is visible
    };
  
    const observerCallback = ( entries, observer ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          // Trigger the SVG animation using play()
          initSvgAnimation( section );
  
          // Stop observing after the animation is triggered
          observer.unobserve( section );
        }
      } );
    };
  
    const observer = new IntersectionObserver( observerCallback, observerOptions );
    observer.observe( section );
  } );
  
  function initSvgAnimation( animationDOM ) {
    const svgObject = animationDOM.querySelector( 'object' );
    svgObject.style.opacity = 0;
    const SVGDocument =
        svgObject.contentDocument || svgObject.contentWindow.document;
    const player = SVGDocument.querySelector( 'svg' )?.svgatorPlayer || null;
    if ( player ) {
      player.play();
      svgObject.style.transition = 'opacity 1s';
      svgObject.style.opacity = 1;
    }
  }
}
  
initAnimationForSection( 'e-stn--key-resource-cta' );
initAnimationForSection( 'e-stn--dual-bottom-cta' );
initAnimationForSection( 'e-stn--interested-in-learning-more-cta' );
initAnimationForSection( 'e-stn--tabs-with-media' );
initAnimationForSection( 'e-stn--request-dtex-briefing-cta' );