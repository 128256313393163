/* eslint-disable no-undef */
document.addEventListener( 'facetwp-refresh', () => {
  FWP.hooks.addAction( 'facetwp/loaded', () => {
    copyIconTooltip ();
  }, 10 );
} );
/* eslint-enable */
document.addEventListener( 'DOMContentLoaded', () => {
  copyIconTooltip ();
} );

function copyIconTooltip () {
  const copyIcons = document.querySelectorAll( '.e-div--resource-copy-icon' );
  if ( copyIcons.length > 0 ) {
    copyIcons.forEach( copyIcon => {
      copyIcon.removeEventListener( 'click', handleCopyIconClick );
      copyIcon.addEventListener( 'click', handleCopyIconClick );
    } );
  }
  document.removeEventListener( 'click', handleDocumentClick );
  document.addEventListener( 'click', handleDocumentClick );
}
function handleCopyIconClick( event ) {
  event.stopPropagation();
  const copyIcon = event.currentTarget;
  const nextSibling = copyIcon.nextElementSibling;
  if ( nextSibling?.classList.contains( 'e-div--social-share-tooltip' ) ) {
    closeAllTooltipsExcept( nextSibling );
    nextSibling.classList.toggle( 'active' );
  }
}

function handleDocumentClick( event ) {
  const copyIcons = document.querySelectorAll( '.e-div--resource-copy-icon' );
  copyIcons.forEach( copyIcon => {
    const siblingElement = copyIcon.nextElementSibling;
    if ( siblingElement?.classList.contains( 'e-div--social-share-tooltip' ) ) {
      if ( !copyIcon.contains( event.target ) && !siblingElement.contains( event.target ) ) {
        siblingElement.classList.remove( 'active' );
      }
    }
  } );
}

function closeAllTooltipsExcept( exception ) {
  const tooltips = document.querySelectorAll( '.e-div--social-share-tooltip' );
  tooltips.forEach( tooltip => {
    if ( tooltip !== exception ) {
      tooltip.classList.remove( 'active' );
    }
  } );
}

