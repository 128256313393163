/* eslint-disable no-unused-vars */
const { feWp } = window;
const { addAction } = feWp.hooks;
export default class SVGAnimation {
  constructor( row ) {
    this.row = row;
    this.svgQueue = [];
    this.isCircular = row.classList.contains( 'e-row--animation-repeat' );
    this.interval = 1000;
    this.activeTimeout = null;
    this.observer = new IntersectionObserver( ( entries ) => {
      this.observerHandler( entries );
    }, {
      root: null,
      rootMargin: '-300px 0px -500px 0px',
    } );
  }

  restart() {
    this.terminate();
    this.fetchSVGs();
    this.play();
  }
  hasSlides() {
    return this.row.querySelectorAll( '.swiper-slide[role="group"]' ).length !== 0;
  }
  fetchSVGs() {
    this.svgQueue = [];
    this.row.querySelectorAll( '.e-svg' ).forEach( svgWrapper => {
      if ( this.hasSlides() ) return;
      const svgatorObject = svgWrapper.querySelector( 'object' );
      if ( !svgatorObject ) {
        return;
      }
      const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow.document;
      if ( !svgatorDocument ) {
        return;
      }
      const svgatorElement = svgatorDocument.querySelector( 'svg' );
      if ( !svgatorElement ) {
        return;
      }
      this.svgQueue.push( svgatorElement );
    } );
  }
  play( index = 0 ) {
    if ( this.svgQueue.length === 0 ) return;
    if ( this.svgQueue.length === index && this.hasSlides() === false ) {
      if ( !this.isCircular ) return;
      index = 0;
    }
    if( !this.svgQueue[index] ) return;
    const player = this.svgQueue[index].svgatorPlayer;
    if ( !player ) {
      return;
    }
    player.on( 'end', () => {
      this.activeTimeout = setTimeout( () => {
        this.play( index + 1 );
      }, this.interval );
      player.off( 'end' );
    } );
    player.play();
  }
  terminate(){
    if( this.activeTimeout ){
      this.svgQueue.forEach( ( svg ) => svg.svgatorPlayer.stop() );
      clearTimeout( this.activeTimeout );
      this.activeTimeout = null;
    }
  }
  observerHandler( entries ) {
    if ( entries[0].isIntersecting ) {
      if( this.isCircular ){
        this.observer.disconnect();
      }
      this.terminate();
      this.play();
    }
  }
  init() {
    this.fetchSVGs();
    this.observer.observe( this.row );
    if ( this.hasSlides() ) {
      const swiperContainer = this.row.querySelector( '.swiper-container' );
      if ( swiperContainer ) {
        const swiper = swiperContainer.swiper;
        if ( swiper ) {
          swiper.on( 'slideChange', () => {
            this.playActiveSlideSVG( swiper.activeIndex );
          } );
          this.observer = new IntersectionObserver( ( entries ) => {
            if ( entries[0].isIntersecting ) {
              this.playActiveSlideSVG( swiper.activeIndex );
            }
          }, {
            root: null,
            rootMargin: '-300px 0px -500px 0px',
          } );
        }
      }
    }
  }

  playActiveSlideSVG( index ) {
    this.terminate();
    const activeSlide = this.row.querySelectorAll( '.swiper-slide' )[index];
    const svgatorObject = activeSlide.querySelector( 'object' );
    if ( !svgatorObject ) return;
    const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow.document;
    if ( !svgatorDocument ) return;
    const svgatorElement = svgatorDocument.querySelector( 'svg' );
    if ( !svgatorElement ) return;
    const player = svgatorElement.svgatorPlayer;
    if ( !player ) return;
    player.on( 'end', () => {
      this.activeTimeout = setTimeout( () => {
        this.playActiveSlideSVG( ( index + 1 ) % this.row.querySelectorAll( '.swiper-slide' ).length );
      }, this.interval );
      player.off( 'end' );
    } );
    player.play();
  }
}

const playActiveSlideSVG = ( index, swiper ) => {
  const activeSlide = swiper.slides[index];
  const svgatorObject = activeSlide.querySelector( 'object' );
  if ( !svgatorObject ) return;
  const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow.document;
  if ( !svgatorDocument ) return;
  const svgatorElement = svgatorDocument.querySelector( 'svg' );
  if ( !svgatorElement ) return;
  const player = svgatorElement.svgatorPlayer;
  if ( !player ) return;
  player.play();
};

const svgAnimatedRows = document.querySelectorAll( '.e-row--common-svg-animation' );
document.addEventListener( 'readystatechange', () => {
  if( document.readyState === 'complete' ) {
    setTimeout( () => {
      svgAnimatedRows.forEach( ( row ) => {
        const svgAnimation = new SVGAnimation( row );
        svgAnimation.init();
      } );
    },2000 );
  }
} );  

window.addEventListener( 'resize', ( ) => {
  setTimeout( () => {
    svgAnimatedRows.forEach( ( row ) => {
      const svgAnimation = new SVGAnimation( row );
      svgAnimation.restart();
    } );
  },2000 );
} );

addAction(
  'b3rg_slider_init',
  'b3rg/slider',
  ( settings, el, swiper ) => {
    if ( swiper.el.closest( '.e-row--common-svg-animation .swiper' ) ) {
      
      swiper.on( 'slideChange', () => {
        setTimeout( () => {
          playActiveSlideSVG( swiper.activeIndex, swiper );
        },1000 );
      } );

      const observer = new IntersectionObserver( ( entries ) => {
        if ( entries[0].isIntersecting ) {
          setTimeout( () => {
            playActiveSlideSVG( swiper.activeIndex, swiper );
          },1000 );
        }
      }, {
        root: null,
        rootMargin: '-300px 0px -500px 0px',
      } );

      observer.observe( el );
    }
  },
  10,
  1,
);
