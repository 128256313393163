document.addEventListener( 'DOMContentLoaded', function () {
  const tabsSection = document.querySelector( '.e-stn--research-hub-banner' );
  if ( !tabsSection ) return;

  const header = document.querySelector( 'header' );
  const body = document.querySelector( 'body' );
  if ( header ) {
    header.classList.add( 'no-box-shadow' );
  }
  if ( body ) {
    body.classList.add( 'unsticky-header' );
  }
} );