/* eslint-disable no-undef */
document.addEventListener( 'facetwp-refresh', () => {
  FWP.hooks.addAction( 'facetwp/loaded', () => {
    setupTooltip();
  }, 10 );
} );
/* eslint-enable */

document.addEventListener( 'DOMContentLoaded', () => {
  setupTooltip();
} );

function setupTooltip() {
  const wrapperElements = document.querySelectorAll( '.heateor_sss_button_copy_link' );
  if ( wrapperElements.length > 0 ) {
    const copiedText = 'Link Copied';
    wrapperElements.forEach( wrapperElement => {
      let tooltip = wrapperElement.querySelector( '.ss-tooltip' );
      if ( !tooltip ) {
        tooltip = document.createElement( 'div' );
        tooltip.className = 'ss-tooltip';
        tooltip.innerHTML = `<p>${copiedText}</p>`;
        wrapperElement.appendChild( tooltip );
      }
      wrapperElement.addEventListener( 'click', () => {
        tooltip.classList.add( 'active' );
        setTimeout( () => {
          tooltip.classList.remove( 'active' );
        }, 1500 );
      } );
    } );
  }
}
