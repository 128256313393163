import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

const stickyContents = gsap.utils.toArray( '.e-acc--floating-content-and-media .e-acc__itm' );

function setupScrollAnimation( stickyContents, images, section ) {
  stickyContents.forEach( ( stickyContent, index ) => {
    const startPosition = window.innerWidth < 991 ? 320 : 400;
    const cardHeight = stickyContent.offsetHeight;

    gsap.to( stickyContent, {
      duration: 1,
      scrollTrigger: {
        trigger: stickyContent,
        start: `top ${startPosition}`,
        end: `+=${cardHeight}`,
        scrub: 0.8,
        invalidateOnResize: true,
        onEnterBack: () => updateImageClass( images, index, section ),
        onEnter: () => updateImageClass( images, index, section ),
      }
    } );
  } );
}

function updateImageClass( images, index, section ) {
  images.forEach( image => image.classList.remove( 'active' ) );
  section.querySelectorAll( 'video' ).forEach( vid => vid.pause() );
  images[index]?.classList.add( 'active' );
  const hasVideo = images[index]?.querySelector( 'video' );
  hasVideo?.play();
}

document.addEventListener( 'DOMContentLoaded', () => {
  document.querySelectorAll( '.e-stn--floating-content-and-media-section' ).forEach( section => {
    const images = section.querySelectorAll( '.e-div--floating-media' );
    const animationDiv = section.querySelector( '.e-svg' );
    const mediaWrapper = document.createElement( 'div' );
    mediaWrapper.classList.add( 'e-acc__media-wrapper' );
    const accordionTabs = section.querySelectorAll( '.e-acc__itm-wrpr' );

    accordionTabs.forEach( slide => {
      const mediaElements = slide.querySelectorAll( '.e-div--floating-media' );
      mediaElements.forEach( element => {
        const clonedElement = element.cloneNode( true );
        mediaWrapper.appendChild( clonedElement );
      } );
    } );
    
    if ( animationDiv ){
      mediaWrapper.appendChild( animationDiv );
    }

    const container = section.querySelector( '.e-acc' );
    container?.appendChild( mediaWrapper );

    mediaWrapper.querySelectorAll( '.e-div--floating-media' )[0]?.classList.add( 'active' );
    setupScrollAnimation( stickyContents, mediaWrapper.querySelectorAll( '.e-div--floating-media' ), section );
    updateImageClass( images, 0, section );
  } );
} );

