document.addEventListener( 'DOMContentLoaded', function () {
  const adminBar = document.getElementById( 'wpadminbar' );
  const adminBarHeight = adminBar ? adminBar.offsetHeight : 0;
  const header = document.querySelector( 'header' );
  const headerHeight = header ? header.offsetHeight : 0;
  const totalHeaderHeight = headerHeight + adminBarHeight;
  const DesktopOffset = 31;
  const mobileScreenOffset = 23;

  document.querySelectorAll( '.e-stn--cookies-policy-content .e-acc__itm-hdr-wrpr' ).forEach( function ( element ) {
    element.addEventListener( 'click', function ( event ) {
      setTimeout( function () {
        const screenWidth = window.innerWidth;
        const offset = screenWidth < 1200 ? mobileScreenOffset : DesktopOffset;
        const elementRect = event.target.getBoundingClientRect().top;
        const elementTopRelativeToDocument = elementRect + window.scrollY;
        const sectionTop = elementTopRelativeToDocument - totalHeaderHeight;

        window.scrollTo( {
          top: sectionTop - offset,
          behavior: 'smooth'
        } );
      }, 450 );
    } );
  } );
} );
