document.addEventListener( 'click', function( e ) {
  if ( e.target.classList.contains( 'facetwp-page' ) ) {
    const adminBar = document.getElementById( 'wpadminbar' );
    const adminBarHeight = adminBar ? adminBar.offsetHeight : 0;
    const headerHeight = document.querySelector( 'header' ).offsetHeight + adminBarHeight;
      
    const section = e.target.closest( 'section' );
    const eventsFilter = section.querySelector( '.e-stn--general-posts' );
    const sectionTop = section.getBoundingClientRect().top + window.scrollY - 25;
    const scrollTopPos = eventsFilter ? ( eventsFilter.getBoundingClientRect().top + window.scrollY ) : sectionTop;
      
    window.scrollTo( {
      top: scrollTopPos - headerHeight,
      behavior: 'smooth'
    } );
  }
  const facetwpSearchInput = document.querySelector( '.facetwp-search' );
  if ( facetwpSearchInput ) {
    const parentFacet = facetwpSearchInput.closest( '.facetwp-facet-search' );
    // If the clicked element is the facetwp-search input
    if ( e.target === facetwpSearchInput ) {
      parentFacet.classList.add( 'facetwp-type-search-selected' );
    } else if ( !parentFacet.contains( e.target ) ) { 
      parentFacet.classList.remove( 'facetwp-type-search-selected' );
    }
  }
} );
