document.querySelectorAll( '.e-tbs__nav .swiper-slide' ).forEach( function( sliderNav ) {
  sliderNav.addEventListener( 'click', function() {
    let videos = document.querySelectorAll( '.swiper video' );
    videos.forEach( function( video ) {
      video.pause();
    } );
    let activeSlide = document.querySelector( '.e-tbs__content .swiper-slide-active video' );
    if ( activeSlide ) {
      activeSlide.play();
    }
  } );
} );