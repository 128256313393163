document.addEventListener( 'DOMContentLoaded', function() {
  const resourceFeatured = document.querySelector( '.e-stn--resource-featured' );
  if ( !resourceFeatured ) {
    return;
  }
  const container = document.querySelector( '.facetwp-template-static' );
  if ( !container ) {
    return; // Exit if the container element is not found
  }
  const cards = container.querySelectorAll( '.e-sgp' );
  if ( cards.length < 2 ) {
    return;
  }
  const wrapper = document.createElement( 'div' );
  container.classList.add( 'featured-flex-main' );
  wrapper.classList.add( 'e-sgp-wrapper' );

  for ( let i = 1; i < cards.length; i++ ) {
    wrapper.appendChild( cards[i] );
  }
  container.appendChild( wrapper );
} );