document.addEventListener( 'DOMContentLoaded', () => {
  const cards = document.querySelectorAll( '.e-stn--platform-features-cards .e-lnk--platform-features-card' );
  
  cards.forEach( card => {
    const clone = card.cloneNode( true );
  
    clone.classList.add( 'platform-features-cloned-card' );
  
    const clonedItems = clone.querySelectorAll( '*' );
    clonedItems.forEach( item => {
      item.classList.add( 'cloned-item' );
    } );

    card.appendChild( clone );
  } );
} );
  
  