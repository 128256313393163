import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin( ScrollTrigger );

document.addEventListener( 'DOMContentLoaded', () => {
  const sections = document.querySelectorAll( '.e-stn--content-media-scroll' );

  sections.forEach( ( section ) => {
    const video = section.querySelector( '.e-vid video' );
    const divs = section.querySelectorAll( '.e-col > .e-div' );

    if ( !video || divs.length === 0 ) {
      return;
    }

    const timestamps = [
      { start: 0, end: 2 },
      { start: 2, end: 4 },
      { start: 4, end: 6 },
    ];

    let playInterval;

    function playVideoSegment( index ) {
      const { start, end } = timestamps[index];

      video.currentTime = start;

      clearInterval( playInterval );

      video.pause();

      video.play().then( () => {
        playInterval = setInterval( () => {
          if ( video.currentTime >= end ) {
            video.pause();
            clearInterval( playInterval );
          }
        }, 50 );
      } ).catch( ( error ) => {
        console.warn( 'Video play was interrupted or failed:', error );
      } );
    }

    divs.forEach( ( div, index ) => {
      const startPosition = window.innerWidth < 991 ? 320 : 400;
      const cardHeight = div.offsetHeight;

      gsap.to( div, {
        duration: 1,
        scrollTrigger: {
          trigger: div,
          start: `top ${startPosition}`,
          end: `+=${cardHeight}`,
          scrub: 0.8,
          invalidateOnResize: true,
          onEnter: () => {
            playVideoSegment( index );
          },
          onEnterBack: () => {
            playVideoSegment( index );
          },
          onLeave: () => {
            if ( playInterval ) {
              clearInterval( playInterval );
            }
            const { end } = timestamps[index];
            video.currentTime = end;
            video.pause();
          },
          onLeaveBack: () => {
            if ( playInterval ) {
              clearInterval( playInterval );
            }
            const { end } = timestamps[index];
            video.currentTime = end;
            video.pause();
          }
        }
      } );
    } );

    video.pause();
    video.currentTime = timestamps[0].start;
    video.removeAttribute( 'autoplay' );

    window.addEventListener( 'load', () => {
      video.pause();
    } );
  } );
} );
